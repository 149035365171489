import { FC, useLayoutEffect } from 'react'
import { CellProps } from 'react-table'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { TRowData } from '../definitions'
import { useComparedVariablesContext } from '../../valuesComparison/state/useComparedVariablesState'
import { getParentRow } from '../../../../utils/table'
import { useKeywordsStateContext } from '../../datasets/state/useKeywordsState'
import { ROUTES } from '../../../../constants/routes'

const LinkStyled = styled.div`
  && {
    text-decoration-color: ${({ theme }) => theme.colors.blue.primary};
    color: ${({ theme }) => theme.colors.blue.primary};
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    text-decoration: underline;
    padding: 0;
    background: transparent;

    &:hover {
      text-decoration: underline;
      background: transparent;
      text-decoration-color: ${({ theme }) => theme.colors.blue.dark};
      color: ${({ theme }) => theme.colors.blue.dark};
    }
  }
`

const VariableCodeCell: FC<CellProps<TRowData>> = ({ row, rowsById, value }) => {
  const { setVariable, clearVariable } = useComparedVariablesContext()
  const parentRow = getParentRow(row, rowsById).original as Required<TRowData>
  const [keywordData] = useKeywordsStateContext().comparedKeywords
  const navigate = useNavigate()

  useLayoutEffect(() => {
    clearVariable()
  }, [])

  const handleClick = () => {
    setVariable({
      variableId: row.original.variableId,
      keyword: keywordData.name,
      acrossDatasets: true,
      countValues: row.original.valuesCount,
      dataset: {
        name: parentRow.name,
        shortName: parentRow.shortName,
        id: parentRow.id
      }
    })

    navigate(ROUTES.COMPARE_VALUES)
  }

  return <LinkStyled onClick={handleClick} onContextMenu={(e) => e.preventDefault()}>{value}</LinkStyled>
}

export default VariableCodeCell
