import { FC } from 'react'
import { CellProps } from 'react-table'
import { TRowData } from '../definitions'
import Checkbox from '../../../shared/Checkbox'
import { useComparedVariablesContext } from '../../valuesComparison/state/useComparedVariablesState'
import { getParentRow } from '../../../../utils/table'
import { useKeywordsStateContext } from '../../datasets/state/useKeywordsState'
import { useWarningModal } from '../../../../context/WarningModalContext'
import { WARNING_DISPLAY_VARIABLE_MAX_COUNT } from '../../../../constants/constants'

const VariableSelectCell: FC<CellProps<TRowData>> = ({ row, rowsById }) => {
  const { comparedVariables, addVariable, removeVariable } = useComparedVariablesContext()
  const isSelected = comparedVariables.some(({ variableId }) => variableId === row.original.variableId)
  const parentRow = getParentRow(row, rowsById).original as Required<TRowData>
  const [keywordData] = useKeywordsStateContext().comparedKeywords
  const { openModal, closeModal } = useWarningModal()

  const handleConfirm = () => {
    openModal(
      () => {
        addVariable(
          {
            variableId: row.original.variableId,
            keyword: keywordData.name,
            acrossDatasets: true,
            countValues: row.original.valuesCount,
            dataset: {
              name: parentRow.name,
              shortName: parentRow.shortName,
              id: parentRow.id
            }
          }
        )
        closeModal()
      },
      () => {
        removeVariable(row.original.variableId)
        closeModal()
      }
    )
  }

  const handleClick = () => {
    if (!isSelected) {
      if (row.original.valuesCount && row.original.valuesCount > WARNING_DISPLAY_VARIABLE_MAX_COUNT) {
        handleConfirm()
      } else {
        addVariable({
          variableId: row.original.variableId,
          keyword: keywordData.name,
          acrossDatasets: true,
          countValues: row.original.valuesCount,
          dataset: {
            name: parentRow.name,
            shortName: parentRow.shortName,
            id: parentRow.id
          }
        })
      }
      return
    }

    removeVariable(row.original.variableId)
  }

  return <Checkbox checked={isSelected} onChange={handleClick} />
}

export default VariableSelectCell
