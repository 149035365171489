import { FC } from 'react'
import { CellProps } from 'react-table'
import { TRowData } from '../definitions'
import { useComparedVariablesContext } from '../../valuesComparison/state/useComparedVariablesState'
import { useDatasetData } from '../hooks/useDatasetData'
import { TDatasetBaseData } from '../../../../definitions/dataset'
import { getParentRow } from '../../../../utils/table'
import Checkbox from '../../../shared/Checkbox'
import { useWarningModal } from '../../../../context/WarningModalContext'
import { WARNING_DISPLAY_VARIABLE_MAX_COUNT } from '../../../../constants/constants'

const VariableSelectCell: FC<CellProps<TRowData>> = ({ row, rowsById }) => {
  const { comparedVariables, addVariable, removeVariable } = useComparedVariablesContext()
  const dataset = useDatasetData() as TDatasetBaseData
  const { variableId } = (row.original as TRowData)
  const { keyword } = (getParentRow(row, rowsById).original as TRowData)
  const isSelected = comparedVariables.some(({ variableId: id }) => id === variableId)
  const { openModal, closeModal } = useWarningModal()

  const handleConfirm = () => {
    openModal(
      () => {
        addVariable(
          {
            keyword: keyword as string,
            variableId,
            acrossDatasets: false,
            dataset,
            countValues: row.original.valuesCount
          }
        )
        closeModal()
      },
      () => {
        removeVariable(row.original.variableId)
        closeModal()
      }
    )
  }

  const handleClick = () => {
    if (!isSelected) {
      if (row.original.valuesCount && row.original.valuesCount > WARNING_DISPLAY_VARIABLE_MAX_COUNT) {
        handleConfirm()
      } else {
        addVariable({
          keyword: keyword as string,
          variableId,
          acrossDatasets: false,
          dataset,
          countValues: row.original.valuesCount
        })
      }
      return
    }
    removeVariable(variableId)
  }

  return <Checkbox checked={isSelected} onChange={handleClick} />
}

export default VariableSelectCell
